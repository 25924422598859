.header-component {
  background-color: #26083e;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
  transition: 0.3s;
  z-index: 9;
}
.header-scroll {
  height: 50px;
}

.button-logo-container {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.logo-img {
  height: 43px;
  cursor: pointer;
  transition: 0.5s;
}
.image-scroll {
  height: 30px;
}
.mobile-drower-container {
  display: flex;
}
.mobile-drower-button {
  color: white !important;
}
@media (min-width: 1001px) {
  .mobile-drower-container {
    display: none;
  }
}
@media (max-width: 1001px) {
  .button-logo-container {
    width: 90%;
  }
}
@media (max-width: 550px) {
  .logo-img {
    height: 33px;
  }
}
@media (max-width: 450px) {
  .header-component {
    height: 70px;
  }
  .logo-img {
    height: 28px;
  }
}
@media (max-width: 365px) {
  .header-component {
    height: 60px;
  }
  .logo-img {
    height: 24px;
  }
}
