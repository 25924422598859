.recents-container {
  background-image: linear-gradient(to top, #e8eefc, #feffff);
  width: 100%;
  height: auto;
  display: flex;
  padding: 120px 0px 120px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.recents-header {
  font-size: 40px;
  text-align: center;
  font-weight: bold;
}
.recents-description {
  font-size: 20px;
  color: gray;
  text-align: center;
  max-width: 600px;
  margin-top: 1rem;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 4rem;
}
.recents-item {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  width: 303px;
  background-color: white;
  height: 365px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.houses-img {
  width: 303px;
  height: 225px;
}
.house-status {
  font-size: 25px;
  font-weight: 500;
  color: #4a90e2;
  margin-top: 10px;
}
.house-description {
  font-size: 20px;
  text-align: center;
  color: #0f2522;
  margin: 15px;
}
.advertise-1-container-desk-big {
  position: relative;
  width: 637px;
  height: 364px;
  grid-column-start: 3;
  grid-column-end: 5;
}
.advertise-1-container-desk-small {
  position: relative;
  display: none;
  height: 480px;
  overflow: hidden;
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 5;
}
.ad-1-cover {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(38, 8, 62, 0.7);
}
.cover-header {
  font-size: 40px;
  max-width: 360px;
  text-align: center;
  color: white;
}
.recents-item-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-gap: 25px 25px;
  justify-content: center;
}

.ad-img1 {
  height: 700px;
  top: -100px;
  position: absolute;
}
@media (max-width: 1620px) {
  .ad-img1 {
    height: 700px;
    top: -100px;
    width: 100%;
    position: absolute;
  }
  .advertise-1-container-desk-big {
    display: none;
  }
  .advertise-1-container-desk-small {
    display: inline;
  }
  .recents-item-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto auto;
    grid-gap: 15px 15px;
  }
}
@media (max-width: 1250px) {
  .advertise-1-container-desk-big {
    display: none;
  }
  .advertise-1-container-desk-small {
    display: inline;
    grid-column-start: 0;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
  }
  .recents-item-container {
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto auto;
    grid-gap: 15px 15px;
    justify-content: center;
  }
}
@media (max-width: 630px) {
  .recents-description {
    font-size: 20px;
  }
  .advertise-1-container-desk-small {
    display: inline;
    grid-column-start: 0;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
  }
  .ad-img1 {
    width: 500px;
  }
  .recents-item-container {
    justify-items: center;
    width: 100%;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-gap: 20px 20px;
  }
  @media (max-width: 629px) {
    .house-description {
      max-width: 384px;
    }
    .cover-header {
      font-size: 30px;
      max-width: 250px;
    }
    .recents-item {
      width: 520px;
      height: fit-content;
      padding: 30px 0px 30px 0;
    }
    .advertise-1-container-desk-small {
      width: 100%;
    }
    .ad-img1 {
      width: auto;
    }
    .houses-img {
      width: 520px;
      height: 290px;
    }
  }
  @media (max-width: 540px) {
    .recents-item {
      width: 470px;
      height: fit-content;
      padding: 20px 0px 20px 0;
    }
    .ad-img1 {
      width: auto;
    }
    .houses-img {
      width: 470px;
      height: 290px;
    }
  }
  @media (max-width: 510px) {
    .recents-item {
      width: 450px;
      height: fit-content;
      padding: 20px 0px 20px 0;
    }
    .ad-img1 {
      width: auto;
    }
    .houses-img {
      width: 450px;
      height: 290px;
    }
  }
  @media (max-width: 470px) {
    .house-status {
      font-size: 20px;
    }
    .house-description {
      font-size: 14px;
      max-width: 200px;
    }
    .recents-item {
      width: 90%;
      height: fit-content;
      padding: 10px 0px 10px 0;
    }
    .ad-img1 {
      width: auto;
    }
    .houses-img {
      width: 95%;
      height: 290px;
    }
  }
  @media (max-width: 400px) {
    .recents-item {
      width: 70%;
      height: fit-content;
      padding: 5px 0px 5px 0;
    }
    .advertise-1-container-desk-small {
      width: 100%;
    }
    .ad-img1 {
      width: auto;
    }
    .houses-img {
      width: 100%;
      height: 150px;
    }
  }
}
