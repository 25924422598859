.contact-us-header {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  margin: 0 30px 0 30px;
}
.contact-us-description {
  max-width: 1000px;
  font-size: 20px;
  text-align: center;
  color: gray;
  margin: 20px 30px 90px 30px;
}
.specialists-container {
  display: flex;
  flex-direction: row;
  width: 900px;
  justify-content: space-around;
}
.specialists-div {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 6px #00000029;
  width: 420px;
  justify-content: center;
  align-items: center;
  height: 250px;
}
.specialist-name {
  font-size: 30px;
  color: #0f2522;
  margin-bottom: 1rem;
}
.specialist-image {
  height: 250px;
  width: 420px;
}
.specialist-info {
  font-size: 19px;
  color: gray;
  margin-bottom: 0.3rem;
}
.specialists-imgAndDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (min-width: 1920px) {
  .contact-us-header {
    font-size: 40px;
  }
}
@media (max-width: 1920px) {
}
@media (max-width: 1280px) {
}
@media (max-width: 1024px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 1000px) {
  .specialists-container {
    flex-direction: column;
    width: 100%;
  }
  .specialists-div {
    margin-bottom: 30px;
  }
}
@media (max-width: 576px) {
  .specialists-div {
    width: 340px;
    height: 180px;
  }
  .specialist-name {
    font-size: 23px;
  }
  .specialist-info {
    font-size: 15px;
  }
  .specialist-image {
    width: 340px;
    height: 220px;
  }
}
@media (max-width: 479px) {
  .specialists-div {
    width: 280px;
    height: 150px;
  }
  .specialist-image {
    width: 280px;
    height: 180px;
  }
  .specialist-name {
    font-size: 18px;
  }
  .specialist-info {
    font-size: 13px;
  }
  @media (max-width: 350px) {
    .contact-us-description {
      font-size: 20px;
    }
    .specialists-div {
      width: 240px;
      height: 140px;
    }
    .specialist-image {
      width: 240px;
      height: 150px;
    }
    .specialist-name {
      font-size: 15px;
    }
    .specialist-info {
      font-size: 12px;
    }
  }
}
