.map-and-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 670px;
}
.mai-info-div {
  color: #3b3b3b;
  padding-top: 30px;
  background: #f0f2f8;
  border-radius: 10px 10px 0px 0px;
}
.mai-header {
  font-size: 42px;
  margin: 0 64px 40px 64px;
  font-weight: bold;
  color: #3b3b3b;
}
.mai-info-items-container {
  display: flex;
  flex-direction: row;
  margin: 0 50px 40px 50px;
  flex-wrap: wrap;
}
.mai-info-single-item-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 20px;
  justify-content: center;
}
.mai-item-info {
  margin-left: 5px;
  display: flex;
  flex-direction: column;
}
.mai-item-info-title {
  font-size: 16px;
  font-weight: bold;
}
@media (max-width: 450px) {
  .map-and-info-container {
    width: 100%;
  }
  .mai-info-items-container {
    margin: 0 25px 20px 25px;
  }
  .mai-info-items-container {
    margin: 0 6px 0px 6px;
  }
  .mai-header {
    font-size: 38px;
    margin: 0 34px 30px 34px;
  }
}
