.header-accordions-segment {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  margin-top: 75px;
  margin-bottom: 27px;
  margin-left: 20px;
  margin-right: 20px;
}
.description-accordions-segment {
  font-size: 20px;
  text-align: center;
  max-width: 500px;
  margin-bottom: 60px;
  margin-right: 20px;
  margin-left: 20px;
}
@media (max-width: 900px) {
  .header-accordions-segment {
    font-size: 40px;
  }
}
@media (max-width: 900px) {
  .header-accordions-segment {
    font-size: 30px;
  }
}
