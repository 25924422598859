.container {
  margin: 0 auto;
  width: 100%;
}
div:focus,
input:focus {
  outline: 0;
}
.carousel-content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: white;
  padding: 10px;
  font-size: 36px;
  height: 500px;
  margin: 0 90px 0 90px;
  padding: 2%;
  position: relative;
  text-align: center;
}

.center .slick-center .carousel-item-head {
  font-size: 33px;
  transition: 0.3s;
}
.center .slick-center .carousel-item-description {
  transition: 0.3s;
  font-size: 18px;
  max-height: 17nherit;
  white-space: inherit;
  overflow: inherit;
  text-overflow: inherit;
}
.center .slick-center .carousel-content {
  margin: 10px;
  opacity: 1;
  height: 620px;
  padding: 20px;
  -ms-transform: scale(1.08);
  transform: scale(1.08);
}
.center .carousel-content {
  transition: all 0.3s ease;
}
.slick-track {
  align-items: center !important;
  display: flex !important;
  justify-content: space-around !important;
  opacity: 1;
  transform: translate3d(-880px, 0px, 0px);
}
.carousel-container {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: 100%;
  align-items: center;
  position: relative;
  justify-content: center;
}
.carousel-background {
  width: 100%;
}
.carousel-div {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.carousel-item-head {
  font-size: 20px;
  transition: 0.3s;
  margin: 25px;
}
.carousel-item-description {
  font-size: 11px;
  color: #0f2522;
  margin: 0 60px 0 60px;
  transition: 0.3s;
  opacity: 0.7;
  max-height: 170px;
  white-space: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
}
.devider {
  margin: 30px 0 30px 0;
  border-bottom: 1px solid gray;
  width: 40%;
}
.carousel-profile {
  border-radius: 50%;
  height: 52px;
  width: 52px;
}
.carousel-name {
  font-size: 26px;
  margin-top: 10px;
}
.carousel-rate {
  margin-bottom: 0px;
}

@media (max-width: 1400px) {
  .center .slick-center .carousel-item-head {
    font-size: 22px;
  }
  .carousel-item-head {
    font-size: 13px;
  }
  .carousel-content {
    height: 400px;
  }
  .center .slick-center .carousel-content {
    height: 550px;
  }
}
@media (max-width: 1200px) {
  .center .slick-center .carousel-content {
    height: 480px;
  }
  .carousel-content {
    height: 400px;
    margin: 0 50px 0 50px;
  }
  .center .slick-center .carousel-item-head {
    margin: 15px;
    width: 70%;
  }
  .carousel-item-head {
    font-size: 22px;
    margin: 15px;
    width: 90%;
  }
  .center .slick-center .carousel-item-description {
    margin: 13px;
  }
  .carousel-item-description {
    font-size: 13px;
  }
  .devider {
    margin: 20px 0 20px 0;
  }
  .carousel-background {
    width: 140%;
  }
}
@media (max-width: 1000px) {
  .center .slick-center .carousel-content {
    height: 430px;
  }
  .carousel-content {
    height: 340px;
    margin: 0 30px 0 30px;
  }
  .center .slick-center .carousel-item-head {
    margin: 14px;
    width: 90%;
  }
  .carousel-item-head {
    font-size: 14px;
    margin: 10px;
    width: 50%;
  }
  .devider {
    margin: 10px 0 10px 0;
  }
  .carousel-background {
    width: 140%;
  }
  .center .slick-center .carousel-item-description {
    transition: 0.3s;
    font-size: 14px;
  }
}
@media (max-width: 800px) {
  .carousel-background {
    width: 160%;
  }
  .center .slick-center .carousel-item-description {
    transition: 0.3s;
    font-size: 13px;
  }
  .carousel-content {
    height: 340px;
    margin: 0 20px 0 20px;
  }
}
@media (max-width: 700px) {
  .carousel-background {
    width: 160%;
    height: 700px;
  }
  .center .slick-center .carousel-item-description {
    transition: 0.3s;
    font-size: 13px;
  }
  .carousel-item-description {
    font-size: 13px;
  }
  .carousel-content {
    height: 340px;
    margin: 0 20px 0 20px;
  }
}
@media (max-width: 560px) {
  .carousel-profile {
    width: 35px;
    height: 35px;
  }
  .carousel-name {
    font-size: 15px;
    margin: 1px;
  }
  .carousel-rate {
    margin: 0;
  }
}
@media (max-width: 450px) {
  .carousel-background {
    width: 160%;
    height: 700px;
  }
  .carousel-content {
    height: 380px;
    margin: 0 13px 0 13px;
  }
  .carousel-profile {
    width: 35px;
    height: 35px;
  }
  .carousel-name {
    font-size: 15px;
    margin: 1px;
  }
  .carousel-rate {
    margin: 0;
  }
}
