.ad-3-img {
  width: 100%;
  height: 900px;
}
.ad3-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  position: relative;
}
.ad-3-cover-container {
  background-color: rgb(0, 0, 0, 0.74);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  position: absolute;
}
.ad-3-info-div {
  display: flex;
  width: 602px;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  color: white;
  position: absolute;
  top: 0;
}
.ad-3-title {
  font-size: 42px;
  margin-bottom: 45px;
  font-weight: bold;
}
.ad-3-description {
  font-size: 22px;
  margin-bottom: 45px;
  font-weight: normal;
}
.ad-3-items-container {
  display: flex;
  flex-direction: row;
  width: 346px;
  align-items: center;
  margin-bottom: 23px;
}
.ad-3-items-text {
  margin-left: 14px;
  font-size: 22px;
}
.ad-3-button {
  background-color: #fa7f28 !important;
  color: white !important;
  font-size: 24px !important;
  margin-top: 70px !important;
}

@media (max-width: 1400px) {
  .ad-3-img {
    height: 700px;
  }
}
@media (max-width: 1024px) {
  .ad-3-img {
    width: 1200px;
    height: 700px;
  }
}
@media (max-width: 700px) {
  .ad-3-info-div {
    width: 90%;
  }
  .ad-3-img {
    height: 890px;
    width: 140%;
  }
}
@media (max-width: 360px) {
  .ad-3-button {
    margin-top: 0px !important;
    margin-bottom: 10px !important;
  }
}
