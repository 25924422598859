.flexible-segment-container {
  padding: 120px 0 120px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flexible-segment-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 789px;
}
.flexible-title {
  font-size: 40px;
  margin-bottom: 25px;
  font-weight: bold;
}
.flexible-description {
  font-size: 22px;
  color: #010101;
}
@media (max-width: 850px) {
  .flexible-segment-div {
    width: 90%;
  }
}
