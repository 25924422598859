.footer-container {
  width: 100%;
  height: auto;
  padding: 4rem 0px 3.6rem 0px;
  background-color: #26083e;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-elements {
  display: flex;
  flex-direction: row;
  max-width: 1170px;
  width: 80%;
}
.footer-elements-item-1 {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-around;
}
.footer-elements-item-1-divs {
  display: flex;
  color: #fbfbfc;
  font-size: 14px;
  flex-direction: column;
}
.footer-elements-item-1-infodivs {
  margin: 1px;
}
.footer-elements-item-1-city {
  font-family: "nl", "Roboto", "Helvetica", "Arial", sans-serif;
  color: #fa7f28;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
}
.footer-img {
  height: 35px;
}
.navigatie-elements {
  color: white;
  margin: 3px;
  font-family: helvetica;
  text-decoration: none;
}
@media (max-width: 980px) {
  .footer-elements {
    flex-direction: column;
  }
  .footer-elements-item-1 {
    width: 100%;
    margin-bottom: 30px;
    justify-content: space-between;
  }
  .footer-img {
    height: 45px;
  }
}
@media (max-width: 630px) {
  .footer-elements-item-1 {
    flex-direction: column;
  }
  .footer-elements-item-1-divs {
    margin-bottom: 2rem;
  }
  .footer-img {
    height: 45px;
    width: 240px;
    margin-bottom: 30px;
  }
  .footer-elements-item-1-city {
    margin-bottom: 0.5rem;
  }
}
