.landing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 95vh;
  background-image: url(../../../assets/backgrounds/background.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
}
.landing-container-title {
  color: white;
  font-size: 45px;
  font-weight: bold !important;
}
.landing-container-description {
  color: white;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
}
