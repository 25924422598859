.accordion-title {
  background-color: #f1f1f1 !important;
  border: 1px solid #cccccc !important;
}
.accordion-contianer {
  width: 960px;
}
.accordion-div {
  margin-bottom: 20px !important;
  background: #fff !important;
}
.accordion-title-text {
  font-size: 24px !important;
  font-weight: bold !important;
}
.accordion-description-text {
  font-size: 20px !important;
}
@media (max-width: 1024px) {
  .accordion-contianer {
    width: 830px;
  }
}
@media (max-width: 900px) {
  .accordion-contianer {
    width: 90%;
  }
  .accordion-description-text {
    font-size: 18px !important;
  }
  .accordion-title-text {
    font-size: 18px !important;
  }
}
