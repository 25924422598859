.contact-description {
  font-size: 20px;
  color: white;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 20px;
  text-align: center;
}
.contact-forms-and-d-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  background-color: rgb(240, 236, 236);
  justify-content: center;
  padding-bottom: 100px;
}
.contact-form {
  color: #575757;
}
::-webkit-input-placeholder {
  opacity: 1 !important;
  /* Chrome/Opera/Safari */
  color: #575757 !important;
}
::-moz-placeholder {
  opacity: 1 !important;
  /* Firefox 19+ */
  color: #575757 !important;
}
:-ms-input-placeholder {
  opacity: 1 !important;
  /* IE 10+ */
  color: #575757 !important;
}
:-moz-placeholder {
  opacity: 1 !important;
  /* Firefox 18- */
  color: #575757 !important;
}
.contact-forms-and-d-div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 900px;
  justify-content: center;
  margin-top: -50px;
}
.contact-forms-continer {
  width: 600px;
  padding: 40px 40px 0px 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: white;
  border-radius: 25px 0 0 25px;
  z-index: 1;
}
.contact-helper-div-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 0 0 20px;
  background-color: #00092d;
  width: 268px;
  height: 100%;
  z-index: 1;
  border-radius: 0 25px 25px 0;
  justify-content: flex-start;
}
.contact-helper-div-city {
  color: #ff9900;
  margin-top: 30px;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}
.contact-helper-div-opentime {
  color: #ff9900;
  font-weight: bold;
  margin-top: 30px;
}
@media (max-width: 1040px) {
  .contact-forms-and-d-div {
    flex-direction: column;
  }
  .contact-forms-continer {
    border-radius: 25px 25px 0 0;
    padding-bottom: 40px;
  }
  .contact-helper-div-container {
    width: 97%;
    border-radius: 0 0 25px 25px;
    padding-bottom: 40px;
  }
  .contact-forms-and-d-container {
    padding-bottom: 250px;
    padding-top: 50px;
  }
}
@media (max-width: 750px) {
  .contact-forms-continer {
    width: 500px;
  }
  .contact-forms-continer {
    width: 70%;
  }
  .contact-helper-div-container {
    padding: 40px 40px 40px 40px;
    width: 70%;
  }
  .contact-forms-and-d-div {
    align-items: center;
  }
}

@media (max-width: 520px) {
  .contact-forms-and-d-container {
    padding-bottom: 250px;
    padding-top: 70px;
  }
}
@media (max-width: 440px) {
  .contact-forms-and-d-container {
    padding-bottom: 250px;
    padding-top: 150px;
  }
}
