.navbar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 420px;
  justify-content: space-between;
}
.navbar-item {
  text-transform: uppercase;
  color: white;
  font-weight: 300;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
  opacity: 1;
}
.navbar-item:hover {
  opacity: 0.7;
}
.navbar-item-selected {
  color: #fa7f28;
}
@media (max-width: 1000px) {
  .navbar-container {
    display: none;
  }
}
@media (max-width: 1080px) {
  .navbar-container {
    margin-left: 70px;
  }
}
.selectlanguage-icon {
  font-size: 22px;
}
.selectlanguage-countryname {
  font-size: 13px;
  margin-left: 7px;
}
