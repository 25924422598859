.waroom-items-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #f1f6fb;
  padding: 40px 0;
}
.waroom-items-div {
  display: flex;
  flex-direction: row-reverse;
  width: 1040px;
  justify-content: space-between;
  align-items: center;
}
.waroom-items {
  width: 500px;
  background-color: white;
  border-radius: 10px;
  margin-top: -70px;
  z-index: 4;
  padding: 25px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 18px 0px rgb(0 0 0 / 20%);
  height: auto;
}
.waroom-item-title {
  font-size: 20px;
  color: #333;
  margin-bottom: 15px;
  font-weight: bold;
}
.red-container {
  color: #e02b20;
  background-color: #f9d5d2;
  width: 133px;
  font-size: 21px;
  font-weight: 500;
  text-align: center;
  padding: 5px 4px;
  margin-top: 15px;
}
.waroom-devider {
  width: 100%;
  height: 1px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  left: 0;
  margin: 35px 0;
}
.warooom-items-options-div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 20px;
  justify-content: center;
  margin-left: 10px;
}
.warooom-items-options-text {
  font-size: 22px;
  color: #333;
  font-weight: bold;
}
.warooom-items-options-icon-true {
  font-size: 24px !important;
  margin-right: 10px;
  color: #4caf50;
  font-weight: bold !important;
}
.warooom-items-options-icon-false {
  font-size: 24px !important;
  margin-right: 10px;
  color: #f44336;
  font-weight: bold !important;
}
.warooom-items-options-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.waroom-price-div {
  background-color: #fdf8ed;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  width: 100%;
  padding: 20px 0;
  align-items: center;
  justify-content: center;
}
.waroom-price-title {
  color: #adadad;
  font-size: 18px;
}
.waroom-price-price {
  color: #000;
  font-size: 35px;
  font-weight: bold;
}
.waroom-foot-text {
  font-size: 15px;
  margin-left: 20px;
  color: #333;
}
.waroom-imgs {
  width: auto;
}
@media (max-width: 1150px) {
  .waroom-items-div {
    flex-direction: column;
    width: 100%;
  }
  .waroom-items {
    margin-bottom: 130px;
    width: 570px;
  }
}
@media (max-width: 570px) {
  .waroom-items {
    width: 90%;
  }
}
@media (max-width: 410px) {
  .cities-video-header {
    font-size: 28px;
  }
  .waroom-imgs {
    width: 80%;
  }
}
