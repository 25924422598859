.calculator-page-container {
  margin-top: 10px;
}
.calculator-info-price-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 100px 0px;
  align-items: center;
  background-image: linear-gradient(to top, #e8eefc, #ffffff);
}
.calculator-info-price-div {
  display: flex;
  width: 1420px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
@media (max-width: 1800px) {
  .calculator-info-price-container {
    padding: 100px 0px;
  }
  .calculator-info-price-div {
    width: 1400px;
  }
}
@media (max-width: 1500px) {
  .calculator-info-price-div {
    width: 1360px;
  }
}
@media (max-width: 1420px) {
  .calculator-info-price-div {
    width: 1230px;
  }
}

@media (max-width: 1279px) {
  .calculator-info-price-div {
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 1000px) {
}
