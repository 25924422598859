.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  justify-content: center;
  min-height: 100vh;
}
.not-found-img {
  width: 689px;
  height: 337px;
}
.not-found-text {
  font-size: 52px;
  color: #26083e;
  margin-top: 45px;
  font-weight: bold;
}
.not-found-btn {
  width: 211px !important;
  height: 52px !important;
  background: #fa7f28 !important;
  border-radius: 5px !important;
  color: white !important;
  margin-top: 40px !important;
  margin-bottom: 100px !important ;
  font-size: 17px !important;
  opacity: 1 !important;
}
