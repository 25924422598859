.postcode-field {
  width: 300px !important;
  height: 50px !important;
  background-color: white !important;
  border-radius: 3px !important;
  margin-right: 10px;
}
.email-field {
  width: 300px !important;
  height: 50px !important;
  background-color: white !important;
  border-radius: 3px !important;
}
.house-number-field {
  width: 200px !important;
  height: 50px !important;
  background-color: white !important;
  border-radius: 3px !important;
  margin-right: 10px;
}
.calculation-fields-div {
  display: flex;
  margin-top: 30px;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  height: 20%;
  margin-top: 50px;
  width: 100%;
}
.submit-button {
  background-color: #fa7f28 !important;
  color: white !important;
  height: 50px !important;
  text-transform: none !important;
}
@media (min-width: 1920px) {
  .postcode-field {
    width: 410px !important;
    height: 63px !important;
  }
  .email-field {
    width: 410px !important;
    height: 63px !important;
  }
  ::placeholder {
    font-size: 18px !important;
  }
  .house-number-field {
    width: 193px !important;
    height: 63px !important;
  }
  .submit-button {
    background: #fa7f28 0% 0% no-repeat padding-box;
    width: 258px !important;
    height: 63px !important;
    border-radius: 5px !important;
    font-size: 24px !important;
  }
}
@media (max-width: 1920px) {
  .postcode-field {
    width: 410px !important;
    height: 63px !important;
  }
  .email-field {
    width: 410px !important;
    height: 63px !important;
  }
  ::placeholder {
    font-size: 18px !important;
  }
  .house-number-field {
    width: 120px !important;
    height: 63px !important;
  }
  .submit-button {
    background: #fa7f28 0% 0% no-repeat padding-box;
    width: 258px !important;
    height: 63px !important;
    border-radius: 5px !important;
    font-size: 24px !important;
  }
}
@media (max-width: 1280px) {
}
@media (max-width: 1024px) {
  .postcode-field {
    width: 217px !important;
    height: 63px !important;
  }
  .email-field {
    width: 217px !important;
    height: 63px !important;
  }
}
@media (max-width: 990px) {
  .calculation-fields-div {
    margin-top: 20px;
  }
}
@media (max-width: 768px) {
  .calculation-fields-div {
    margin-top: 0px;
    height: 40%;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .house-number-field {
    width: 80% !important;
    height: 50px !important;
    margin: 0;
  }
  .postcode-field {
    width: 80% !important;
    height: 50px !important;
    margin: 0;
  }
  .email-field {
    width: 80% !important;
    height: 50px !important;
    margin: 0;
  }
  ::placeholder {
    font-size: 15px !important;
  }
  .submit-button {
    width: 80% !important;
    height: 50px !important;
    font-size: 20px !important;
    transition: 0.1s !important;
  }
}
@media (max-width: 480px) {
  .house-number-field {
    height: 45px !important;
  }
  .postcode-field {
    height: 45px !important;
  }
  .email-field {
    height: 45px !important;
  }
  .submit-button {
    width: 180px;
    height: 45px !important;
    font-size: 16px !important;
    transition: 0.1s !important;
  }
}
@media (max-width: 375px) {
}
@media (max-width: 320px) {
}
.calculation-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.calculation-modal-content {
  text-align: center;
  position: relative;
  min-width: 800px;
  height: 728px;
  background-image: linear-gradient(to bottom right, #fff, #e6e8fd);
  width: 80%;
}
.calculation-modal-content h1 {
  text-align: center;
  font: normal normal bold 60px/72px;
  letter-spacing: 0px;
  color: #3b3b3b;
  opacity: 1;
}
.calculation-modal-content-fixed-box {
  width: 108px;
  height: 19px;
  background: #fa7f28 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 100px;
  margin-bottom: 35px;
  margin-top: 20px;
  margin-left: 30px;
}
.calculation-modal-content input {
  width: 100%;
}
.modal-btn {
  background-color: #fa7f28 !important;
  width: 100%;
  height: 63px;
  text-align: center;
  font: normal normal medium 24px/29px !important;
  letter-spacing: 0px;
  color: #ffffff !important;
  opacity: 1;
  margin-top: 50px;
}
.check-box-final .Mui-checked {
  color: #fa7f28 !important;
}
.check-box-final .MuiIconButton-label {
  transform: scale(2) !important;
}
.calculation-modal-progress .Mui-checked {
  color: #fa7f28 !important;
}
.calculation-modal-progress {
  width: 100%;
  margin-bottom: 10px;
}
.calculation-modal-progress-check-icon {
  width: 20%;
  float: right;
}
.calculation-modal-progress-check-icon .MuiIconButton-root {
  padding: 0 !important;
}
.calculation-modal-content .background-image {
  position: absolute;
  right: 0;
  bottom: 80px;
  left: 50%;
  transform: translate(-50%, 0);
}
.calculation-modal-content .progress {
  position: absolute;
  right: 0;
  bottom: 160px;
  left: 50%;
  transform: translate(-73%, 0);
  width: 200px;
  display: flex;
  flex-direction: column;
}
.calculation-modal-content-animation {
  background-image: url("../../../../assets/images/waiting-background.svg");
}
.calculation-modal-progress-bar {
  width: 80%;
  float: left;
}
.modal-content-email-step {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
}
.modal-content-email-step-container {
  display: flex;
  flex-direction: column;
  margin-top: 395px;
  padding-left: 294px;
  padding-right: 230px;
}
.modal-content-error-container {
  text-align: center;
  position: relative;
  background-image: linear-gradient(to bottom right, #fff, #e6e8fd);
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.modal-content-email-step-container p {
  text-align: center;
  font: normal normal normal 12px/16px;
  letter-spacing: 0px;
  opacity: 1;
}
.modal-content-email-step-container button {
  font: normal normal medium 24px/29px;
}
.modal-content-email-step-container a {
  color: #2a9cd4;
  cursor: pointer;
}
.calculation-modal-content-bg {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100% !important;
  text-align: center;
  position: relative;
  min-width: 800px;
  height: 828px;
  backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.5);
}

@media (max-width: 768px) {
  .custom-error {
    padding: 40px !important;
  }
  .modal-content-email-step {
    flex-direction: column;
  }
  .calculation-modal-content {
    width: 100%;
  }
  .modal-content-email-step-container {
    margin-top: 120px;
    padding-left: 228px;
    padding-right: 230px;
  }
  .modal-content-email-step-container .postcode-field {
    width: 100% !important;
  }
  .modal-content-email-step-container .email-field {
    width: 100% !important;
  }
  .calculation-modal-conten button {
    width: 226px;
    font-size: 12px;
  }
  .calculation-modal {
    flex-direction: column;
  }
}
@media (max-width: 375px) {
  .custom-error {
    padding: 10px !important;
  }
}
@media (max-width: 320px) {
  .custom-error {
    padding: 5px !important;
  }
}
