.ad-2-img {
  width: 100%;
  height: 1080px;
}
.sec-ad-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  position: relative;
}
.ad-2-info-div {
  display: flex;
  width: 603px;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  color: white;
  position: absolute;
  top: 0;
}
.ad-2-title {
  font-size: 48px;
  margin-bottom: 45px;
  font-weight: bold;
}
.ad-2-description {
  font-size: 22px;
  margin-bottom: 45px;
  font-weight: normal;
}
.ad-2-items-container {
  display: flex;
  flex-direction: row;
  width: 316px;
  align-items: center;
  margin-bottom: 23px;
}
.ad-2-items-text {
  margin-left: 14px;
  font-size: 22px;
}
.ad-2-button {
  background-color: #fa7f28 !important;
  color: white !important;
  font-size: 24px !important;
  margin-top: 70px !important;
}
@media (max-width: 1400px) {
  .ad-2-img {
    height: 700px;
  }
}
@media (max-width: 1024px) {
  .ad-2-img {
    width: 1200px;
    height: 700px;
  }
}
@media (max-width: 700px) {
  .ad-2-info-div {
    width: 90%;
  }
  .ad-2-img {
    height: 820px;
  }
}
