.calculator-advan-container {
  background-color: #ffffff;
  padding: 120px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.calculator-advan-header {
  font-size: 50px;
  text-align: center !important;
  font-weight: bold;
  color: #3b3b3b;
  margin-bottom: 120px;
}
.calculator-advan-options-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 1238px;
  margin-left: 140px;
  justify-content: space-between;
}
.calculator-advan-options-div {
  display: flex;
  flex-direction: column;
  width: 500px;
  align-items: flex-start;
  justify-content: center;
}

.calculator-advan-options-header {
  font-size: 38px;
  color: #3b3b3b;
  margin-bottom: 21px;
  font-weight: bold;
}

.ca-options-div {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: fit-content;
  justify-content: center;
  align-items: flex-start;
}

.ca-options-description {
  font-size: 22px;
  margin-left: 10px;
}
@media (max-width: 1420px) {
  .calculator-advan-options-container {
    width: 90%;
  }
}
@media (max-width: 1265px) {
  .calculator-advan-container {
    padding: 120px 0 50px 0;
  }
  .calculator-advan-options-container {
    margin-left: 0;
    flex-direction: column;
    align-items: center;
  }
  .ca-check-img {
    width: 23px;
    margin-top: 10px;
    height: 23px;
  }
  .calculator-advan-options-header {
    display: none;
  }
  .calculator-advan-header {
    margin-bottom: 40px;
    margin-right: 20px;
    margin-left: 20px;
  }
  .ca-options-div {
    margin-bottom: 10px;
  }
  .ca-options-description {
    font-size: 40px;
  }
}
@media (max-width: 700px) {
  .calculator-advan-options-div {
    width: 100%;
  }
  .ca-check-img {
    margin-top: 5px;
  }
  .calculator-advan-header {
    font-size: 45px;
    text-align: left;
  }
  .ca-options-description {
    font-size: 30px;
  }

  .calculator-advan-options-container {
    align-items: flex-start;
    margin-left: 30px;
  }
}
