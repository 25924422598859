.advantages-segment-div {
  width: 100%;
  height: auto;
  display: flex;
  padding: 120px 0px 120px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to top, #e8eefc, #feffff);
}
.advantages-segment-text-head {
  font-size: 40px;
  text-align: center;

  margin: 0 30px 0 30px;
  font-weight: 999;
}
.advantages-segment-text-description {
  font-size: 20px;
  margin-top: 1.7rem;
  width: 80%;
  max-width: 730px;
  text-align: center;
  color: gray;
}
.advantages-options-container {
  display: flex;
  height: auto;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4rem;
  align-items: center;
  width: 80%;
}
.advantages-item-head {
  font-size: 24px;
  text-align: center;
  margin-bottom: 6px;
}
.advantages-item-description {
  font-size: 20px;
  text-align: center;
  color: gray;
  max-width: 300px;
}
.advantages-options-div {
  display: flex;
  padding: 60px 10px 60px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 170px;
  width: 240px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
}
.advantages-options-icon-div {
  background-color: #fa7f28;
  width: 120px;
  height: 120px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: center;
  border-radius: 100%;
}

@media (min-width: 1920px) {
  .advantages-segment-text-head {
    font-size: 40px;
  }
  .advantages-segment-text-description {
    font-size: 20px;
  }
  .advantages-options-div {
    width: 398px;
    height: 398px;
    padding: 0px 0px 0px 0px;
  }
  .advantages-options-icon-div {
    height: 120px;
    width: 120px;
  }
  .advantages-item-head {
    font-size: 30px;
    font-weight: 900;
  }
  .advantages-options-container {
    width: 1400px;
  }
  .advantages-item-description {
    font-size: 20px;
  }
}
@media (max-width: 1290px) {
  .advantages-segment-text-head {
    font-size: 40px;
    margin-bottom: 10px;
  }
  .advantages-options-container {
    width: 850px;
  }
  .advantages-segment-text-description {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .advantages-item-description {
    font-size: 20px;
  }
}
@media (max-width: 1919px) {
  .advantages-item-head {
    margin-bottom: 20px;
  }
  .advantages-options-icon-div {
    margin-bottom: 30px;
  }
  .advantages-options-container {
    width: 1230px;
  }
  .advantages-options-div {
    height: 390px;
    width: 390px;
    padding: 0;
  }
  .advantages-item-head {
    font-weight: 900;
    font-size: 24px;
  }
}
@media (max-width: 1250px) {
  .advantages-options-container {
    width: 1100px;
  }

  .advantages-options-div {
    height: 340px;
    width: 340px;
    padding: 0;
  }
}
@media (max-width: 1140px) {
  .advantages-options-container {
    width: 1000px;
  }
  .advantages-options-div {
    height: 340px;
    width: 310px;
    padding: 0;
  }
}
@media (max-width: 1024px) {
  .advantages-segment-text-description {
    font-size: 20px;
    width: 70%;
  }
  .advantages-options-container {
    height: 1220px;
    flex-direction: column;
  }
  .advantages-options-div {
    padding: 20px 10px 20px 10px;
  }
  .advantages-options-div {
    width: 398px;
    height: 348px;
  }
  .advantages-item-head {
    font-size: 30px;
  }
  .advantages-options-icon-div {
    margin-bottom: 20px !important;
  }
}

@media (max-width: 1000px) {
}
@media (max-width: 990px) {
  .advantages-options-container {
    width: 90%;
  }
}
@media (max-width: 768px) {
  .advantages-options-div {
    padding: 65px 10px 65px 10px;
  }
  .advantages-options-container {
    height: 800px;
  }
  .advantages-options-div {
    height: 120px;
  }
  .advantages-options-icon-div {
    height: 80px;
    width: 80px;
  }
}
@media (max-width: 580px) {
}
@media (max-width: 480px) {
  .advantages-item-head {
    font-size: 20px;
  }
  .advantages-segment-div {
    padding: 120px 0px 120px 0px;
  }
  .advantages-options-div {
    width: 80%;
    flex-direction: column;
  }
  .advantages-segment-text-description {
    font-size: 20px;
    width: 90%;
    margin-top: 1rem;
  }
  .advantages-item-description {
    font-size: 20px;
  }
}
@media (max-width: 375px) {
  .advantages-options-icon-div {
    margin-bottom: 4px;
  }

  .advantages-item-head {
    font-size: 20px;
  }
  .advantages-segment-text-description {
    font-size: 20px;
    margin-top: 0.6rem;
  }
}
@media (max-width: 320px) {
  .advantages-segment-text-description {
    font-size: 20px;
    margin-top: 0.6rem;
  }
  .advantages-item-description {
    font-size: 20px;
  }
}
