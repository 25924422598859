.amsterdam-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cities-video-container {
  height: 360px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.cities-video {
  width: 100%;
}
.cities-video-div {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.cities-video-header {
  font-size: 50px;
  color: white;
  font-weight: bold;
  text-align: center;
  max-width: 384px;
}
.amsterdam-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 120px 0;
  width: 100%;
  align-items: center;
  background-image: linear-gradient(to top, #e8eefc, #ffffff);
}
.amsterdam-item-1-container {
  display: flex;
  width: 1200px;
  margin-bottom: 150px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.amsterdam-item-2-container {
  display: flex;
  width: 1200px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.house-and-girl-img {
  width: auto;
}
.amsterdam-item-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.amsterdam-header {
  font-weight: bold;
  max-width: 552px;
  color: #3b3b3b;
  font-size: 36px;
  margin-bottom: 20px;
}
.amsterdam-description {
  font-size: 22px;
  max-width: 530px;
  margin-bottom: 20px;
  color: #3b3b3b;
}
@media (max-width: 1560px) {
  .amsterdam-item-1-container {
    width: 1200px;
  }
  .amsterdam-item-2-container {
    width: 1200px;
  }
}
@media (max-width: 1249px) {
  .amsterdam-item-1-container {
    flex-direction: column;
    width: 95%;
  }
  .amsterdam-item-2-container {
    flex-direction: column;
    width: 95%;
  }
  .amsterdam-header {
    margin-top: 80px;
  }
  .amsterdam-div {
    padding: 80px 0;
  }
}
@media (max-width: 620px) {
  .house-and-girl-img {
    width: 100%;
  }
  .cities-video {
    width: 170%;
  }
  .amsterdam-item-1-container {
    width: 87%;
  }
  .amsterdam-item-2-container {
    width: 87%;
  }
  .cities-video-header {
    margin: 0 40px;
    font-size: 35px;
  }
}
@media (max-width: 350px) {
  .cities-video {
    width: 210%;
  }
}
