.paf-container {
  max-width: 670px;
}
.paf-first-letter {
  font-size: 20px;
  font-weight: bold;
}
.paf-price-range {
  text-align: left;
  font-weight: bolder;
  font-size: 50px;
  margin: 30px 0 30px 0;
  letter-spacing: 0px;
  color: #039673;
}
.paf-description {
  font-size: 22px;
}
.paf-options-cntainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
  align-items: flex-start;
  justify-content: center;
}
.paf-options-div {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: fit-content;
  justify-content: center;
  align-items: flex-start;
}
.paf-options-description {
  font-size: 22px;
  margin-left: 20px;
}
.paf-forms-1-div {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.paf-1-field {
  width: 100% !important;
  height: 40px !important;
  background-color: white !important;
  border-radius: 3px !important;
  margin-right: 10px;
}
.paf-1-field fieldset {
  border: 0;
}
.paf-2-field {
  height: 110px !important;
}
.MuiOutlinedInput-inputMultiline {
  height: 93px !important;
}
.privacy-container {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.privacy-description {
  font-size: 12px;
  max-width: 430px;
  color: #3b3b3b;
}
.privacy-button {
  width: 176px !important;
  color: white !important;

  height: 40px !important;
  background: #fa7f28 !important;
}
.privacy-open-button {
  padding: 10px;
  color: white !important;
  height: 70px !important;
  background: #fa7f28 !important;
  margin-top: 100px !important;
  width: 407px;
}
.privacy-anchor {
  text-decoration: none;
  color: #2a9cd4;
}

@media (max-width: 1400px) {
  .paf-container {
    width: 520px;
  }
  .privacy-description {
    width: 300px !important;
  }
}
@media (max-width: 1279px) {
  .paf-container {
    margin-top: 120px;
  }
}
@media (max-width: 1000px) {
  .paf-container {
    width: 490px;
  }
}
@media (max-width: 320px) {
  .privacy-open-button {
    max-width: 260px !important;
  }
}
@media (max-width: 530px) {
  .privacy-open-button {
    max-width: 300px;
  }
  .paf-price-range {
    font-size: 36px;
  }
  .paf-first-letter {
    font-size: 20px;
  }
  .paf-description {
    font-size: 20px;
  }
  .paf-container {
    width: 390px;
  }
  .paf-options-description {
    font-size: 20px;
  }
  .privacy-container {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  .privacy-button {
    width: 100% !important;
    height: 60px !important;
  }
  .privacy-description {
    margin-top: 20px !important;
    width: 100% !important;
  }
}
@media (max-width: 440px) {
  .paf-container {
    width: 80%;
  }
  .paf-price-range {
    font-size: 33px;
  }
  .paf-forms-1-div {
    flex-direction: column;
  }
  .paf-1-field {
    margin: 0;
    height: 60px !important;
  }
  .paf-2-field {
    height: 110px !important;
  }
}
.modal-content-email-step {
  justify-content: space-between;
}
.modal-content-email-step .MuiOutlinedInput-root {
  width: 49% !important;
  height: 50px !important;
  background-color: white !important;
  border-radius: 5px !important;
}
@media (max-width: 768px) {
  .modal-content-email-step {
    flex-direction: column;
  }
  .modal-content-email-step .MuiOutlinedInput-root {
    width: 100% !important;
    margin-bottom: 20px;
  }
}
