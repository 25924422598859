.eindhoven-accordion-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 120px 0;
}
.eindhoven-accordion-header {
  color: #3b3b3b;
  text-align: center;
  font-size: 50px;
  width: fit-content;
  font-weight: bold;
  margin: 0 50px 24px 50px;
}
.eindhoven-accordion-description {
  color: #3b3b3b;
  text-align: center;
  font-size: 20px;
  margin: 0 50px 50px 50px;
  max-width: 670px;
}
@media (max-width: 500px) {
  .eindhoven-accordion-header {
    font-size: 40px;
  }
}
