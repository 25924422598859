.custom-error {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  padding: 183px;
}
.custom-error h1 {
  color: #3b3b3b;
  text-align: center;
  font: normal normal bold 60px/72px;
  letter-spacing: 0px;
  color: #3b3b3b;
  opacity: 1;
}
.custom-error p {
  height: 90px;
  text-align: center;
  font: normal normal 300 20px/30px;
  letter-spacing: 0.17px;
  color: #3b3b3b;
  opacity: 1;
}
.custom-error button {
  max-width: 358px;
  width: 100%;
  height: 60px;
  background: #fa7f28 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font: normal normal medium 19px/23px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
