.home-calculate-div {
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}
.home-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.calculator-text-head {
  color: white;
  font-size: 50px;
  font-weight: 999;
  text-align: center;
  padding: 0 30px 0 30px;
  transition: 0.3s;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.calculator-text-description {
  width: 90%;
  color: white;
  font-size: 20px;
  text-align: center;
  transition: 0.3s;
}
.video-and-calculator-div {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.video-tag {
  width: 100%;
}

@media (min-width: 1920px) {
  .calculator-text-head {
    font-size: 90px;
  }
  .calculator-text-description {
    font-size: 35px;
    width: 750px;
  }
}
@media (max-width: 1920px) {
  .calculator-text-head {
    font-size: 90px;
  }
  .calculator-text-description {
    font-size: 35px;
    width: 750px;
  }
}
@media (max-width: 1390px) {
  .calculator-text-head {
    font-size: 60px;
  }
  .calculator-text-description {
    font-size: 25px;
    width: 750px;
  }
}
@media (max-width: 1024px) {
  .calculator-text-head {
    font-size: 60px;
  }
  .calculator-text-description {
    font-size: 25px;
    width: 650px;
    margin-bottom: 20px;
  }
}
@media (min-width: 990px) {
}

@media (max-width: 770px) {
  .calculator-text-head {
    font-size: 45px;
  }
  .calculator-text-description {
    font-size: 25px;
    width: 450px;
  }
  .video-tag {
    width: 330%;
  }
}
@media (max-width: 480px) {
  .calculator-text-head {
    font-size: 40px;
  }
  .calculator-text-description {
    font-size: 22px;
    width: 350px;
  }
}
@media (max-width: 375px) {
  .video-tag {
    width: 380%;
  }
}
@media (max-width: 320px) {
  .calculator-text-description {
    width: 90%;
  }
}
