.rotterdam-description-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 120px 0;
  width: 100%;
}
.rotterdam-individual-header {
  font-size: 42px;
  font-weight: bold;
  text-align: center;
  color: #3b3b3b;
  margin-bottom: 100px;
}
.rotterdam-description-header {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 50px;
  color: #3b3b3b;
}
.rotterdam-description {
  color: #3b3b3b;
  letter-spacing: 0.18px;
  line-height: 1.5;
  white-space: pre-line;
  font-size: 22px;
}
